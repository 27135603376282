.tw-service-message {
  display: flex;
  width: 100%;
  min-height: 85vh;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: #040327;
}

.tw-service-message__text {
  font-family: Raleway, monospace;
  font-size: 18px;
  font-weight: bold;
  color: #7cbc5e;
}

.tw-service-message__logo {
  margin-top: 30px;
}
