@import "~normalize.css";

/* Raleway regular */
@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/raleway/Raleway-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Raleway bold */
@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/raleway/Raleway-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Comfortaa regular */
@font-face {
  font-family: "Comfortaa";
  src: url("./assets/fonts/comfortaa/regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Comfortaa bold */
@font-face {
  font-family: "Comfortaa";
  src: url("./assets/fonts/comfortaa/bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Josefin Slab regular */
@font-face {
  font-family: "Josefin Slab";
  src: url("./assets/fonts/josefin-slab/regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Josefin Slab bold */
@font-face {
  font-family: "Josefin Slab";
  src: url("./assets/fonts/josefin-slab/bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Montserrat regular */
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Montserrat medium */
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Montserrat bold */
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Application styles */
html,
body {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  min-height: 100%;
  max-width: 31.25em;
  min-width: 18.75em;
  margin: 0 auto;
  text-align: center;
  background-color: #040327;
}

/* replace react-perfect-scrollbar styles */
.ps__rail-y {
  width: 5px !important;
  background-color: rgba(226, 228, 229, 0.7) !important;
  border-radius: 10px;
  opacity: 1 !important;
}

.ps__thumb-y {
  right: 0px !important;
  width: 5px !important;
  background-color: rgba(38, 34, 98, 0.9) !important;
  border-radius: 10px;
}
